/* eslint-disable max-lines */
import { hideLoading, showLoading } from 'react-redux-loading-bar'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import app from '../../config/app'
import Candidacy from '../../data/models/Candidacy'
import OfferCard from '../../data/models/OfferCard'
import ApiSagas from '../../redux/api/sagas'
import { actions as AppActions } from '../../redux/app/redux'
import { actions as authActions, selectors as authSelectors } from '../../redux/auth/redux'
import { selectors as TranslationSelectors } from '../../redux/i18n/redux'
import routes, { Router } from '../../routes'

import { deleteOffer, getOwnedOffer, markCandidaciesAsRejected, markCandidaciesAsSelected } from './queries'
import { actions, selectors } from './redux'


export default class UserOfferSagas {

  static* init(ctx) {
    yield put(showLoading())

    const { query } = ctx

    if (query.id) {
      const result = yield call(UserOfferSagas.reloadOffer, query.id)

      if (result) {
        return result
      }
    }

    const seo = yield call(UserOfferSagas.transformSeo)

    yield all([
      put(AppActions.setJsonld(null)),
      put(AppActions.setSeo(seo)),
      put(actions.setLoaded(true)),
      put(hideLoading()),
    ])

    return {}
  }

  static* reloadOffer(id) {
    const offerResult = yield call(ApiSagas.query, getOwnedOffer, { reference: id })

    if (offerResult.errors || !offerResult.data.offer) {
      yield put(hideLoading())
      return { error: 404 }
    }

    const currentOffer = OfferCard(offerResult.data.offer)

    const allCandidacies = {
      data: offerResult.data.offer?.candidacies?.data?.map(Candidacy),
      paginatorInfo: offerResult.data.offer?.candidacies?.paginatorInfo,
    }

    const selectedCandidacies = {
      data: offerResult.data.offer?.selectedCandidacies?.data?.map(Candidacy),
      paginatorInfo: offerResult.data.offer?.selectedCandidacies?.paginatorInfo,
    }

    const rejectedCandidacies = {
      data: offerResult.data.offer?.rejectedCandidacies?.data?.map(Candidacy),
      paginatorInfo: offerResult.data.offer?.rejectedCandidacies?.paginatorInfo,
    }

    yield put(actions.setAllCandidacies(allCandidacies))
    yield put(actions.setSelectedCandidacies(selectedCandidacies))
    yield put(actions.setRejectedCandidacies(rejectedCandidacies))

    yield put(actions.setData(currentOffer))
  }

  static* transformSeo() {
    const i18nLang = yield select(TranslationSelectors.lang)
    const i18nStore = yield select(TranslationSelectors.store)
    const companyTitleLabel = i18nStore[i18nLang].translation.SEO_company_new_offer_title
    const companyDescriptionLabel = i18nStore[i18nLang].translation.SEO_company_new_offer_description

    const seoProps = {
      title: companyTitleLabel,
      image: '',
      description: companyDescriptionLabel,
      url: `${app.APP_URL}${Router.getRouteUrl(routes.userPublishOffer)}`,
      noindex: true,
    }

    return seoProps
  }

  static* unMarkCandidacies({ payload }) {
    const { markedCandidacies } = payload
    const { id: offerId } = yield select(selectors.data)

    const result = yield call(ApiSagas.query, markCandidaciesAsSelected,
      { offer: offerId, candidacies: markedCandidacies })

    if (result.errors) {
      console.log('UserOffer: Errors', result.errors)
      yield put(actions.submitOfferError(result.errors))
    } else {
      yield call(UserOfferSagas.reloadOffer, result.data.markCandidaciesAsSelected.reference)
    }
  }

  static* markCandidaciesAsSelected({ payload }) {
    const { selectedCandidacies } = payload
    const { id: offerId } = yield select(selectors.data)

    const result = yield call(ApiSagas.query, markCandidaciesAsSelected,
      { offer: offerId, candidacies: selectedCandidacies })

    if (result.errors) {
      console.log('UserOffer: Errors', result.errors)
      yield put(actions.markCandidaciesAsSelectedError(result.errors))
    } else {
      yield call(UserOfferSagas.reloadOffer, result.data.markCandidaciesAsSelected.reference)
      yield put(actions.markCandidaciesAsSelectedSuccess(result.data.markCandidaciesAsSelected))
    }
  }

  static* markCandidaciesAsRejected({ payload }) {
    const { rejectedCandidacies } = payload
    const { id: offerId } = yield select(selectors.data)

    const result = yield call(ApiSagas.query, markCandidaciesAsRejected,
      { offer: offerId, candidacies: rejectedCandidacies })

    if (result.errors) {
      console.log('UserOffer: Errors', result.errors)
      yield put(actions.markCandidaciesAsRejectedError(result.errors))
    } else {
      yield call(UserOfferSagas.reloadOffer, result.data.markCandidaciesAsRejected.reference)
      yield put(actions.markCandidaciesAsRejectedSuccess(result.data.markCandidaciesAsRejected))
    }
  }

  static* deleteOffer({ payload }) {
    const result = yield call(ApiSagas.query, deleteOffer, { id: payload })

    if (result.errors) {
      console.log('UserOffer: Errors', result.errors)
      yield put(actions.deleteOfferError(result.errors))
    } else {
      const user = yield select(authSelectors.user)
      const offers = Array.from(user?.offers)

      const index = offers.findIndex((o) => o.id === payload)

      offers.splice(index, 1)

      yield put(authActions.setUser({ ...user, company: { ...user?.company, offers } }))

      Router.replaceRoute(routes.userPublications.name)
    }
  }

  static* loop() {
    yield all([
      takeLatest(actions.markCandidaciesAsSelected.getType(), UserOfferSagas.markCandidaciesAsSelected),
      takeLatest(actions.markCandidaciesAsRejected.getType(), UserOfferSagas.markCandidaciesAsRejected),
      takeLatest(actions.unMarkCandidacies.getType(), UserOfferSagas.unMarkCandidacies),
      takeLatest(actions.deleteOffer.getType(), UserOfferSagas.deleteOffer),
    ])
  }

}
