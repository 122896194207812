import gql from 'graphql-tag'

import { query } from '../../helpers/GraphqlHelpers'
import { PaginationFragment } from '../../graphql/query/Fragments/PaginationFragment'


const offerCandidacyFragment = gql`
    fragment offerCandidacyFragment on Candidacy {
        id
        applied_at
        message
        files {
            id
            preview_url
            pdf_url
            title
        }
        user {
            company {
                id
                slug
                full_slug
                name
                city
                logo
                is_ogcn
            }
        }
    }
`

const offerFragment = gql`
    fragment userOfferFragment on Offer {
        id
        reference
        label
        full_slug
        maximal_execution_at
        city
        published_at
        categories {
            id
            name
            color
        }
        candidacies(first: 50){
           data {
               ... offerCandidacyFragment
           }
            paginatorInfo {
                ... pagination
            }
        } 
        selectedCandidacies(first: 50){
            data {
                ... offerCandidacyFragment
            }
            paginatorInfo {
                ... pagination
            }
        }
        rejectedCandidacies(first: 50){
            data {
                ... offerCandidacyFragment
            }
            paginatorInfo {
                ... pagination
            }
        }
    }
  ${offerCandidacyFragment}
  ${PaginationFragment.fragment}
`

export const getOffer = (variables, headers) => query(gql`
    query offerByReference($reference: String!) {
        offer:offerByReference(reference: $reference) {
            ...userOfferFragment
        }
    }
    ${offerFragment}
  `,
variables, headers)

export const getOwnedOffer = (variables, headers) => query(gql`
    query ownedOfferByReference($reference: String!) {
        offer:ownedOfferByReference(reference: $reference) {
            ...userOfferFragment
        }
    }
    ${offerFragment}
  `,
variables, headers)

export const deleteOffer = (variables, headers) => query(gql`
    mutation deleteOffer($id: ID!) {
        deleteOffer(id: $id) {
            id
        }
    }
  `,
variables, headers)

export const unMarkCandidacies = (variables, headers) => query(gql`
    mutation unMarkCandidacies($offer: ID!, $candidacies: [ID!]) {
        unMarkCandidacies(offer: $offer, candidacies: $candidacies) {
            reference
        }
    }
  `,
variables, headers)

export const markCandidaciesAsSelected = (variables, headers) => query(gql`
    mutation markCandidaciesAsSelected($offer: ID!, $candidacies: [ID!]) {
        markCandidaciesAsSelected(offer: $offer, candidacies: $candidacies) {
            reference
        }
    }
  `,
variables, headers)

export const markCandidaciesAsRejected = (variables, headers) => query(gql`
    mutation markCandidaciesAsRejected($offer: ID!, $candidacies: [ID!]) {
        markCandidaciesAsRejected(offer: $offer, candidacies: $candidacies) {
            reference
        }
    }
  `,
variables, headers)
