import { createAction, createReducer } from 'redux-act'
import Immutable from 'seamless-immutable'


import { requestInitialState, requestReducers } from '../../helpers/ReduxHelpers'
import Errors from '../../data/models/Errors'


export const key = 'UserOffer'


//
// Initial state
//
const initialState = {
  data: null,
  loaded: false,
  deleteOffer: requestInitialState(),
  unMarkCandidacies: requestInitialState(),
  markCandidaciesAsSelected: requestInitialState(),
  markCandidaciesAsRejected: requestInitialState(),
  //
  allCandidacies: {},
  selectedCandidacies: {},
  rejectedCandidacies: {},
  //
  loadAllCandidacies: requestInitialState(),
  loadSelectedCandidacies: requestInitialState(),
  loadRejectedCandidacies: requestInitialState(),
  //
  fillOffer: requestInitialState(),
  archiveOffer: requestInitialState(),
}

//
// Actions
//
export const actions = {
  setData: createAction('userOffer: setData', (data) => ({ data })),
  setAllCandidacies: createAction('userOffer: setAllCandidacies', (allCandidacies) => ({ allCandidacies })),
  setSelectedCandidacies: createAction('userOffer: setSelectedCandidacies', (selectedCandidacies) => ({ selectedCandidacies })),
  setRejectedCandidacies: createAction('userOffer: setRejectedCandidacies', (rejectedCandidacies) => ({ rejectedCandidacies })),
  setLoaded: createAction('userOffer: setLoaded', (loaded) => ({ loaded })),
  //
  deleteOffer: createAction('userOffer: deleteOffer', (values) => (values)),
  deleteOfferError: createAction('userOffer: deleteOfferError', (errors) => Errors(errors)),
  deleteOfferSuccess: createAction('userOffer: deleteOfferSuccess', (data) => ({ data })),
  //
  loadAllCandidacies: createAction(`${key}: loadAllCandidacies`, (page) => ({ page })),
  loadSelectedCandidacies: createAction(`${key}: loadSelectedCandidacies`, (page) => ({ page })),
  loadRejectedCandidacies: createAction(`${key}: loadRejectedCandidacies`, (page) => ({ page })),
  //
  unMarkCandidacies: createAction(`${key}: unMarkCandidacies`,
    (markedCandidacies) => ({ markedCandidacies })),
  //
  markCandidaciesAsSelected: createAction(`${key}: markCandidaciesAsSelected`,
    (selectedCandidacies) => ({ selectedCandidacies })),
  markCandidaciesAsSelectedError: createAction('userOffer: markCandidaciesAsSelectedError', (errors) => Errors(errors)),
  markCandidaciesAsSelectedSuccess: createAction('userOffer: markCandidaciesAsSelectedSuccess', (data) => ({ data })),
  //
  markCandidaciesAsRejected: createAction(`${key}: markCandidaciesAsRejected`,
    (rejectedCandidacies) => ({ rejectedCandidacies })),
  markCandidaciesAsRejectedError: createAction('userOffer: markCandidaciesAsRejectedError', (errors) => Errors(errors)),
  markCandidaciesAsRejectedSuccess: createAction('userOffer: markCandidaciesAsRejectedSuccess', (data) => ({ data })),
  //
}

//
// Reducer
//
export const reducer = createReducer({
  [actions.setData]: (state, { data }) => Immutable.static.merge(state, { data }),
  [actions.setAllCandidacies]: (state, { allCandidacies }) => Immutable.static.merge(state, { allCandidacies }),
  // eslint-disable-next-line max-len
  [actions.setSelectedCandidacies]: (state, { selectedCandidacies }) => Immutable.static.merge(state, { selectedCandidacies }),
  // eslint-disable-next-line max-len
  [actions.setRejectedCandidacies]: (state, { rejectedCandidacies }) => Immutable.static.merge(state, { rejectedCandidacies }),
  [actions.setLoaded]: (state, { loaded }) => Immutable.static.merge(state, { loaded }),
  ...requestReducers(actions, 'deleteOffer'),
  ...requestReducers(actions, 'unMarkCandidacies'),
  ...requestReducers(actions, 'markCandidaciesAsSelected'),
  ...requestReducers(actions, 'markCandidaciesAsRejected'),
}, initialState)

//
// Selectors
//

const root = (state) => state[key]
const isLoaded = (state) => root(state).loaded
const data = (state) => root(state).data
const deleteOffer = (state) => root(state).deleteOffer
const loadAllCandidacies = (state) => root(state).loadAllCandidacies
const allCandidacies = (state) => root(state).allCandidacies
const selectedCandidacies = (state) => root(state).selectedCandidacies
const rejectedCandidacies = (state) => root(state).rejectedCandidacies
const loadSelectedCandidacies = (state) => root(state).loadSelectedCandidacies
const loadRejectedCandidacies = (state) => root(state).loadRejectedCandidacies
const markCandidaciesAsSelected = (state) => root(state).markCandidaciesAsSelected
const markCandidaciesAsRejected = (state) => root(state).markCandidaciesAsRejected

export const selectors = {
  isLoaded,
  data,
  deleteOffer,
  //
  allCandidacies,
  selectedCandidacies,
  rejectedCandidacies,
  //
  loadAllCandidacies,
  loadSelectedCandidacies,
  loadRejectedCandidacies,
  //
  markCandidaciesAsSelected,
  markCandidaciesAsRejected,
}
